import React, { Component } from 'react'

class Landing extends Component {
  render() {
    return(
      <div className="landing">
        <div className="container">
          <div className="intro-placeholder">
          </div>
        </div>
      </div>
    )
  }
}

export default Landing;
